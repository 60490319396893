import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import QueryList from 'queries/QueryList'
import QueryController from 'queries/QueryController'
import client from 'client'

class QueriesController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queries: {},
      query: '',
    }
  }

  componentWillMount() {
    client.listQueries().then((queries) => {
      this.setState({ queries })
    })
  }

  handleQuery(key) {
    const { queries } = this.state
    this.setState({ query: queries.find((query) => query.key === key) })
  }

  render() {
    const { queries, query } = this.state
    return (
      <Container>
        <QueryList queries={queries} onQuery={(key) => this.handleQuery(key)} />
        {query && <QueryController query={query} />}
      </Container>
    )
  }
}

export default QueriesController

import React, { Component } from 'react'
import { Segment, List } from 'semantic-ui-react'

class QueryList extends Component {
  getQueryList() {
    const { queries, onQuery } = this.props
    const items = Object.values(queries).map((query) => (
      <List.Item key={query.key} onClick={() => onQuery(query.key)}>
        <span className="link">{query.name}</span> - {query.description}
      </List.Item>
    ))
    return <List>{items}</List>
  }

  render() {
    return <Segment>{this.getQueryList()}</Segment>
  }
}

export default QueryList

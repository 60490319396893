import React, { Component } from 'react'
import { Segment, Form } from 'semantic-ui-react'

class SelectCustomerForm extends Component {
  constructor(props) {
    super(props)

    this.state = { name: '' }
  }

  handleNameChange = (event, { value }) => {
    this.setState({ name: value })
  }

  handleChange(props, state) {
    const { onChange } = props
    const { name } = state
    onChange({ name })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleChange(this.props, this.state)
    }
  }

  render() {
    const { name } = this.state
    const { customers } = this.props

    if (customers) {
      customers.sort((a, b) => a.value.localeCompare(b.value))
    }

    return (
      <Segment>
        <Form.Field>
          <label>Customer</label>
          {customers && (
            <Form.Select
              placeholder="Select customer"
              options={customers}
              defaultValue={name}
              onChange={this.handleNameChange}
              required
              search
            />
          )}
        </Form.Field>
      </Segment>
    )
  }
}

export default SelectCustomerForm

import React, { Component } from 'react'
import { Form, Button } from 'semantic-ui-react'

const USER_ROLES = [
  {
    text: 'Admin',
    value: 'admin',
  },
  {
    text: 'Read-only',
    value: 'viewer',
  },
]

class UserComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { ...props }
  }

  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value })
  }

  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value })
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleRoleChange = (event, { value }) => {
    this.setState({ role: value })
  }

  handleUpdateUser = (props) => {
    const { onUpdateUser, uid } = props
    const { firstName, lastName, email, role } = this.state

    onUpdateUser(uid, firstName, lastName, email, role)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleUpdateUser(this.props)
    }
  }

  handleRemoveUser = (event, props) => {
    event.preventDefault()
    const { onRemoveUser, uid } = props
    onRemoveUser(uid)
  }

  render() {
    const { collectNames, firstName, lastName, email, role } = this.state

    return (
      <Form.Group>
        {collectNames && (
          <Form.Group>
            <Form.Field>
              <label>First Name</label>
              <input
                placeholder="First Name"
                defaultValue={firstName}
                onChange={this.handleFirstNameChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input
                placeholder="Last Name"
                defaultValue={lastName}
                onChange={this.handleLastNameChange}
                required
              />
            </Form.Field>
          </Form.Group>
        )}
        <Form.Field>
          <label>Email</label>
          <input
            placeholder="Email"
            defaultValue={email}
            onChange={this.handleEmailChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Role</label>
          <Form.Select
            placeholder="Select user role"
            options={USER_ROLES}
            defaultValue={role}
            onChange={this.handleRoleChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Actions</label>
          <Button
            content="Remove"
            negative
            onClick={(event) => this.handleRemoveUser(event, this.state)}
          />
        </Form.Field>
      </Form.Group>
    )
  }
}

export default UserComponent

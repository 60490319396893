import React, { Component } from 'react'
import { Segment, Form, Header } from 'semantic-ui-react'

class QueryParameters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      model: {},
    }
  }

  componentWillReceiveProps(nextProps) {
    const { query } = this.props
    const { query: newQuery } = nextProps
    if (query.key !== newQuery.key) {
      this.setState({ model: {} })
    }
  }

  handleInput(key, value) {
    const model = Object.assign({}, this.state.model)
    model[key] = value
    this.setState({ model })
  }

  getField(parameter) {
    const { model } = this.state
    return (
      <Form.Field key={parameter.key}>
        <label>{parameter.name}</label>
        <input
          placeholder={parameter.placeholder}
          onChange={(e) => this.handleInput(parameter.key, e.target.value)}
          value={model[parameter.key] || ''}
        />
      </Form.Field>
    )
  }

  render() {
    const { query, onSubmit } = this.props
    const { model } = this.state
    const fields = query.parameters.map((p) => this.getField(p))
    return (
      <Segment>
        <Header>Parameters</Header>
        <Form>
          {fields}
          <Form.Button
            type="submit"
            onClick={() => onSubmit(model)}
            content="Submit"
          />
        </Form>
      </Segment>
    )
  }
}

export default QueryParameters

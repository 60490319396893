import React, { PureComponent, Fragment } from 'react'
import { Table } from 'semantic-ui-react'
import LoadingText from 'components/LoadingText'

const WHITELISTED_FIELDS = [
  'uuid',
  'name',
  'device_count',
  'archived',
  'maintenance',
  'on_trial',
  'date_created',
  'greenlake_platform_customer_id'
]

class CustomerDetails extends PureComponent {
  getRow(key, value) {
    return (
      <Table.Row key={key}>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell>{value ? value.toString() : ""}</Table.Cell>
      </Table.Row>
    )
  }

  getTable() {
    const { details } = this.props
    const rows = []
    for (let field of WHITELISTED_FIELDS) {
      if (details[field] !== undefined) {
        const row = this.getRow(field, details[field])
        rows.push(row)
      }
    }
    return (
      <Table basic="very" celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Attribute</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    )
  }

  render() {
    const { isLoading } = this.props
    return (
      <Fragment>
        {isLoading && <LoadingText timeout={3} />}
        {!isLoading && this.getTable()}
      </Fragment>
    )
  }
}

export default CustomerDetails

import React, { Component } from 'react'
import { Container, Header } from 'semantic-ui-react'
import LoadingText from 'components/LoadingText'
import HealthResult from 'health/HealthResult'

class HealthStatus extends Component {
  render() {
    const { results, isLoading } = this.props
    return (
      <div>
        <Header>Aruba UXI Services</Header>
        {isLoading && <LoadingText />}
        <Container fluid={true} className="status">
          {!isLoading &&
            Object.keys(results).map((key) => (
              <HealthResult key={key} host={key} value={results[key]} />
            ))}
        </Container>
      </div>
    )
  }
}

export default HealthStatus

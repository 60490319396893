import React, { Component } from 'react'
import { List, Segment, Label, Button } from 'semantic-ui-react'
import ReactJson from 'react-json-view'
import TimeAgo from 'react-timeago'

export class SensorStatusItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDetails: false,
    }
  }

  toggleIsDetails() {
    const { isDetails } = this.state
    this.setState({ isDetails: !isDetails })
  }

  renderWebPage(value) {
    return <Segment dangerouslySetInnerHTML={{ __html: value }} />
  }

  renderTime(value) {
    const now = new Date()
    const then = now.getTime() - value * 1000
    return <TimeAgo date={then} />
  }

  renderColour(value) {
    if (!value.startsWith('#')) {
      value = '#' + value
    }

    const style = {
      backgroundColor: value,
      verticalAlign: 'bottom',
    }

    return <Label circular={true} size="medium" style={style} />
  }

  isWebPage(value) {
    return value.html !== null
  }

  isNested(value) {
    return typeof value === 'object'
  }

  isTime(key) {
    return key.endsWith('data_age') || key.endsWith('data_delay')
  }

  isColour(value) {
    const hexColourRegex = /^(#?)[0-9a-f]{6}$/i
    return hexColourRegex.test(value)
  }

  prettify(key, value) {
    if (this.isNested(value)) {
      if (this.isWebPage(value)) {
        return this.renderWebPage(value.html)
      }
      return 'n/a' // placeholder until we identify/fix all the nested responses from -api
    }
    if (this.isTime(key)) {
      return this.renderTime(value)
    } else if (this.isColour(value)) {
      return this.renderColour(value)
    }
    return value
  }

  render() {
    const { entry, path } = this.props
    const { isDetails } = this.state
    const pathString = path.join('.')
    return (
      <List.Item key={pathString}>
        <List.Content>
          <Segment secondary>
            <Label attached="top">{pathString}</Label>
            <Button
              toggle
              basic
              compact
              size="tiny"
              floated="right"
              active={isDetails}
              onClick={() => this.toggleIsDetails()}
            >
              details
            </Button>
            {entry.problem && (
              <div className="sensor-status-line">
                <span className="sensor-status-heading">Problem:</span>{' '}
                {entry.problem}
              </div>
            )}
            {entry.recommendation && (
              <div className="sensor-status-line">
                <span className="sensor-status-heading">Recommendation:</span>{' '}
                {entry.recommendation}
              </div>
            )}
            <div className="sensor-status-line">
              <span className="sensor-status-heading">Details:</span>{' '}
              {this.prettify(pathString, entry.value)}
            </div>
            {isDetails && (
              <Segment>
                <ReactJson src={entry} />
              </Segment>
            )}
          </Segment>
        </List.Content>
      </List.Item>
    )
  }
}

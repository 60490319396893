import { MESSAGE_STRUCT_SUCCESS } from 'utils/forms/messages'
import {
  ISSUE_SEVERITY_ERROR,
  ISSUE_SEVERITY_WARNING,
} from 'utils/forms/errors'

class FormController {
  constructor(form) {
    this.form = form
  }

  makeRequestMessage = (type, title, text) => {
    return { type, title, text }
  }

  makeValidationError = (text) => {
    return { type: 'error', title: 'Validation failed', text }
  }

  validate = () => {
    const validated = true
    const messages = []
    return { validated, messages }
  }

  submit = () => null

  handleSubmit = () => {
    const { validated, messages } = this.validate()
    if (!validated) {
      this.form.setState({ messages })
      return
    }

    this.form.setState({ isSending: true }, () => {
      this.submit(this.form.state)
        .then((results) => {
          let hasErrors = false

          if (results.issues) {
            results.issues.forEach((issue) => {
              switch (issue.severity) {
                case ISSUE_SEVERITY_ERROR:
                  messages.push(
                    this.makeRequestMessage('error', issue.name, issue.detail)
                  )
                  hasErrors = true
                  break

                case ISSUE_SEVERITY_WARNING:
                  messages.push(
                    this.makeRequestMessage('warning', issue.name, issue.detail)
                  )
                  break

                default:
                  break
              }
            })
          }

          if (!hasErrors) {
            messages.push(MESSAGE_STRUCT_SUCCESS)
          }

          this.form.setState({ results, messages, isSending: false })
        })
        .catch((error) => {
          this.form.setState({ isSending: false, messages })
        })
    })
  }
}

export default FormController

import React, { Component } from 'react'
import { Segment, Header } from 'semantic-ui-react'
import ReactJson from 'react-json-view'
import LoadingText from 'components/LoadingText'

class QueryResult extends Component {
  render() {
    const { result, loading } = this.props
    return (
      <Segment>
        <Header>Result</Header>
        {loading && <LoadingText />}
        {!loading && <ReactJson src={result} />}
      </Segment>
    )
  }
}

export default QueryResult

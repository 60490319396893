import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'

const WHITELISTED_FIELDS = [
  'uid',
  'name',
  'surname',
  'email',
  'home_customer_name',
  'role',
  'restricted',
  'namespace',
  'greenlake_global_id',
  'home_customer_uid',
  'deleted',
  'disabled',
  'date_created',
  'datetime_updated',
]

class UserDetails extends Component {
  getRow(key, value) {
    return (
      <Table.Row key={key}>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell>{value ? value.toString() : ""}</Table.Cell>
      </Table.Row>
    )
  }

  render() {
    const { details } = this.props
    let rows = []
    for (let field of WHITELISTED_FIELDS) {
      if (details[field] !== undefined) {
        const row = this.getRow(field, details[field])
        rows.push(row)
      }
    }

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Attribute</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    )
  }
}

export default UserDetails

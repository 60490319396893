import React, { useState, useEffect } from 'react'
import { Segment, Form } from 'semantic-ui-react'

import { useAuth0 } from 'react-auth0-wrapper.js'

const RequesterForm = ({ onChange }) => {
  const {
    user: { email },
  } = useAuth0()
  const [comment, setComment] = useState()

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  useEffect(() => {
    onChange({ email, comment })
  }, [email, comment, onChange])

  return (
    <Segment>
      <Form.Field>
        <label>Requester Email</label>
        <input placeholder="Email" value={email} disabled required />
      </Form.Field>
      <Form.Field>
        <label>Intercom Chat Link</label>
        <input
          placeholder="https://app.intercom.io/a/apps/fwuuxbw4/inbox/inbox/all/conversations/1234"
          defaultValue={comment}
          onChange={handleCommentChange}
          required
        />
      </Form.Field>
    </Segment>
  )
}

export default RequesterForm

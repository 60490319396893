import React, { Component, Fragment } from 'react'
import { Header, Tab } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { hotkeys } from 'react-keyboard-shortcuts'
import queryString from 'query-string'
import PortalHeader from 'components/PortalHeader'
import SensorsController from 'summary/SensorsController'
import UsersController from 'summary/UsersController'
import CustomerDetails from 'summary/CustomerDetails'
import BackButton from 'components/BackButton'
import client from 'client'

class CustomerSummaryController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      customer: {},
      activeTabIndex: 0,
    }
  }

  hot_keys = {
    left: {
      handler: () => {
        const { activeTabIndex } = this.state
        this.setState({ activeTabIndex: Math.max(activeTabIndex - 1, 0) })
      },
    },
    right: {
      handler: () => {
        const { activeTabIndex } = this.state
        this.setState({ activeTabIndex: Math.min(activeTabIndex + 1, 2) })
      },
    },
  }

  componentWillMount() {
    const { uuid } = this.props.match.params
    this.handleDefaultTab()
    this.setState({ isLoading: true }, () => {
      client.retrieveCustomer(uuid).then((customer) => {
        this.setState({ isLoading: false, customer })
      })
    })
  }

  handleDefaultTab() {
    const { search } = this.props.location
    const parsed = queryString.parse(search)
    if ('sensorsFilter' in parsed) {
      this.setState({ activeTabIndex: 1 })
    } else if ('usersFilter' in parsed) {
      this.setState({ activeTabIndex: 2 })
    } else {
      this.setState({ activeTabIndex: 0 })
    }
  }

  handleTabChange(tabIndex) {
    this.setState({ activeTabIndex: tabIndex })
  }

  render() {
    const { history } = this.props
    const { uuid } = this.props.match.params
    const { search } = this.props.location
    const parsed = queryString.parse(search)
    const { isLoading, customer, activeTabIndex } = this.state
    const panes = [
      {
        menuItem: 'Customer Details',
        render: () => (
          <Tab.Pane>
            <CustomerDetails isLoading={isLoading} details={customer} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Sensors',
        render: () => (
          <Tab.Pane>
            <SensorsController
              customerUuid={uuid}
              sensorSerial={parsed.sensorSerial}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Users',
        render: () => (
          <Tab.Pane>
            <UsersController customerUuid={uuid} userUuid={parsed.userUuid} />
          </Tab.Pane>
        ),
      },
    ]

    return (
      <Fragment>
        <PortalHeader>
          <Header as="h1" className="portal-header">
            Customer: {customer.name}
            <BackButton
              tabIndex="1"
              floated="right"
              onClick={() => history.goBack()}
            >
              Back
            </BackButton>
          </Header>
        </PortalHeader>
        <Tab
          panes={panes}
          activeIndex={activeTabIndex}
          onTabChange={(e, { activeIndex }) =>
            this.handleTabChange(activeIndex)
          }
        />
      </Fragment>
    )
  }
}

export default withRouter(hotkeys(CustomerSummaryController))

import React, { Component } from 'react'
import { SystemStatusContext } from 'health/systemStatus'
import client from 'client'

class SystemStatusProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results: {},
      isLoading: false,
    }
  }

  fetchSystemStatus() {
    this.setState({ isLoading: true }, () => {
      client.retrieveServicesHealth().then((results) => {
        this.setState({ results, isLoading: false })
      })
    })
  }

  poll() {
    this.handle = window.setInterval(() => {
      this.fetchSystemStatus()
    }, 30000)
    this.fetchSystemStatus()
  }

  componentDidMount() {
    this.poll()
  }

  componentWillUnmount() {
    window.clearInterval(this.handle)
  }

  render() {
    const { children } = this.props
    return (
      <SystemStatusContext.Provider value={this.state}>
        {children}
      </SystemStatusContext.Provider>
    )
  }
}

export default SystemStatusProvider

import React, { Component, Fragment } from 'react'
import { Table } from 'semantic-ui-react'
import { hotkeys } from 'react-keyboard-shortcuts'

class ExpandableTable extends Component {
  constructor(props) {
    super(props)
    const { rows } = this.props
    this.state = {
      expandedRows: new Set(),
      selectedRow: rows.length > 0 ? rows[0] : {},
    }
  }

  hot_keys = {
    up: {
      priority: 1,
      handler: () => {
        const { rows } = this.props
        const { selectedRow } = this.state
        const newSelectedRow = rows[Math.max(rows.indexOf(selectedRow) - 1, 0)]
        this.setState({ selectedRow: newSelectedRow })
        return false
      },
    },
    down: {
      priority: 1,
      handler: () => {
        const { rows } = this.props
        const { selectedRow } = this.state
        const newSelectedRow =
          rows[Math.min(rows.indexOf(selectedRow) + 1, rows.length - 1)]
        this.setState({ selectedRow: newSelectedRow })
        return false
      },
    },
    enter: {
      priority: 1,
      handler: () => {
        const { selectedRow } = this.state
        this.handleExpand(selectedRow)
      },
    },
  }

  handleExpand = (row) => {
    const { idLookup } = this.props
    const { expandedRows } = this.state
    this.setState({ selectedRow: row })

    const rowId = idLookup ? idLookup(row) : row.id

    if (expandedRows.has(rowId)) {
      expandedRows.delete(rowId)
    } else {
      expandedRows.add(rowId)
    }
    this.setState({ expandedRows })
  }

  getRows = () => {
    const { rows, getRow, getExpansion, idLookup } = this.props
    const { expandedRows, selectedRow } = this.state

    return rows.map((row) => {
      const rowId = idLookup ? idLookup(row) : row.id

      return (
        <Fragment key={rowId}>
          {getRow(row, selectedRow.id === rowId, () => this.handleExpand(row))}
          {expandedRows.has(rowId) && getExpansion(row)}
        </Fragment>
      )
    })
  }

  componentWillReceiveProps(newProps) {
    const { rows } = this.props
    const { rows: newRows } = newProps
    if (newRows !== rows) {
      this.setState({
        selectedRow: newRows.length > 0 ? newRows[0] : {},
        expandedRows: new Set(),
      })
    }
  }

  render() {
    const { getHeader } = this.props
    const rows = this.getRows()
    return (
      <Table celled padded>
        {getHeader(rows)}
        <Table.Body>{rows}</Table.Body>
      </Table>
    )
  }
}

export default hotkeys(ExpandableTable)

import React, { Component } from 'react'
import { Segment, Form, Button } from 'semantic-ui-react'
import DeviceComponent from 'requests/components/DeviceComponent'

class AssignDeviceForm extends Component {
  constructor(props) {
    super(props)

    this.state = { devices: {}, lastID: 0 }
  }

  addDevice = (event) => {
    event.preventDefault()
    const { devices, lastID } = this.state
    const updatedDevices = { ...devices }
    const key = `device-${lastID + 1}`
    updatedDevices[key] = { serial: '', macAddress: '' }
    this.setState({ devices: updatedDevices, lastID: lastID + 1 })
  }

  removeDevice = (key) => {
    const { devices } = this.state
    const updatedDevices = { ...devices }
    delete updatedDevices[key]
    this.setState({ devices: updatedDevices })
  }

  updateDevice = (key, serial, macAddress) => {
    const { devices } = this.state
    const updatedDevices = { ...devices }
    updatedDevices[key] = { serial, macAddress }
    this.setState({ devices: updatedDevices })
  }

  handleChange(props) {
    const { onChange } = props
    onChange(this.state.devices)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleChange(this.props)
    }
  }

  renderDevice(device, key) {
    return (
      <DeviceComponent
        {...device}
        key={key}
        uid={key}
        onUpdateDevice={this.updateDevice}
        onRemoveDevice={this.removeDevice}
      />
    )
  }

  renderDevices(devices) {
    const components = []
    Object.keys(devices).forEach((key) => {
      components.push(this.renderDevice(devices[key], key))
    })
    return components
  }

  render() {
    const { devices } = this.state

    return (
      <Segment>
        {this.renderDevices(devices)}
        <Form.Field>
          <Button
            content="Assign Device"
            color="blue"
            onClick={this.addDevice}
          />
        </Form.Field>
      </Segment>
    )
  }
}

export default AssignDeviceForm

import React, { Component, Fragment } from 'react'
import LoadingText from 'components/LoadingText'
import UserDetails from 'summary/UserDetails'
import client from 'client'

class UserDetailsController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details: {},
      isLoading: false,
    }
  }

  handleUpdateDetails = () => {
    const { uid } = this.props
    this.setState({ isLoading: true }, () => {
      client.retrieveUser(uid).then((details) => {
        this.setState({ isLoading: false, details })
      })
    })
  }

  componentDidMount() {
    this.handleUpdateDetails()
  }

  componentDidUpdate(prevProps) {
    const { id: prevId } = prevProps
    const { id } = this.props
    if (id !== prevId) {
      this.handleUpdateDetails()
    }
  }

  render() {
    const { isLoading, details } = this.state
    return (
      <Fragment>
        {isLoading && <LoadingText timeout={3} />}
        {!isLoading && <UserDetails details={details} />}
      </Fragment>
    )
  }
}

export default UserDetailsController

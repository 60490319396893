import React, { Component } from 'react'
import { Segment, Form } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'

class SelectDateComponent extends Component {
  constructor(props) {
    super(props)

    const date = props.date || new Date().toISOString().substring(0, 10)
    this.state = { date, originalDate: date }
  }

  handleDateChange = (event, { value }) => {
    this.setState({ date: value })
  }

  handleChange(props, state) {
    const { onChange } = props
    const { date } = state
    onChange({ date })
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.date || nextProps.date === null) &&
      nextProps.date !== this.state.date
    ) {
      this.setState({ date: nextProps.date })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleChange(this.props, this.state)
    }
  }

  render() {
    const { label, date } = this.state
    let expiryDate = date || new Date().toISOString().substring(0, 10)

    return (
      <Segment>
        <Form.Field>
          <label>{label}</label>
          <DateInput
            placeholder="Select date"
            value={expiryDate}
            dateFormat="YYYY-MM-DD"
            iconPosition="left"
            onChange={this.handleDateChange}
            hidden
            required={true}
          />
        </Form.Field>
      </Segment>
    )
  }
}

export default SelectDateComponent

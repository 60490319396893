import React, { Component } from 'react'
import { Container, Form, Button, Header } from 'semantic-ui-react'
import NewCustomerForm from 'requests/forms/NewCustomerForm'
import RequesterForm from 'requests/forms/RequesterForm'
import AssignUserForm from 'requests/forms/AssignUserForm'
import AssignDeviceForm from 'requests/forms/AssignDeviceForm'
import NewCustomerFormController from 'utils/forms/controllers/NewCustomerFormController'
import { renderMessages } from 'utils/forms/messages'

class NewCustomerController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      request: null,
      customer: null,
      users: null,
      linkedUsers: null,
      devices: null,
      isSending: false,
    }
    this.controller = new NewCustomerFormController(this)
  }

  onRequestChanged = (request) => {
    this.setState({ request })
  }

  onCustomerDetailsChanged = (customer) => {
    this.setState({ customer })
  }

  onUsersChanged = (users) => {
    this.setState({ users })
  }

  onLinkedUsersChanged = (linkedUsers) => {
    this.setState({ linkedUsers })
  }

  onDevicesChanged = (devices) => {
    this.setState({ devices })
  }

  render() {
    const { isSending, messages } = this.state
    const hasError = messages.some((message) => message.type === 'error')
    const hasWarning = messages.some((message) => message.type === 'warning')
    const isSuccess = !hasError && !hasWarning

    return (
      <Container>
        <Header as="h1" dividing>
          Create New Customer
        </Header>
        <Form
          error={hasError}
          warning={hasWarning}
          success={isSuccess}
          onSubmit={this.controller.handleSubmit.bind(this)}
          loading={isSending}
        >
          {renderMessages(messages)}

          <Header as="h3" dividing>
            CUSTOMER DETAILS
          </Header>
          <NewCustomerForm onChange={this.onCustomerDetailsChanged} />

          <Header as="h3" dividing>
            USERS
          </Header>
          <AssignUserForm onChange={this.onUsersChanged} collectNames={true} />

          <Header as="h3" dividing>
            LINKED USERS
          </Header>
          <AssignUserForm
            onChange={this.onLinkedUsersChanged}
            collectNames={false}
          />

          <Header as="h3" dividing>
            DEVICES
          </Header>
          <AssignDeviceForm onChange={this.onDevicesChanged} />

          <Header as="h3" dividing>
            REQUEST DETAILS
          </Header>
          <RequesterForm onChange={this.onRequestChanged} />

          <Button type="submit" disabled={isSending}>
            Submit
          </Button>
        </Form>
      </Container>
    )
  }
}

export default NewCustomerController

import React, { Component } from 'react'
import { Modal, Button, Icon } from 'semantic-ui-react'
import { hotkeys } from 'react-keyboard-shortcuts'
import client from 'client'
import ReactMarkdown from 'react-markdown'

class HelpModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      helpDocument: '',
    }
  }

  componentDidMount() {
    client.retrieveHelpDocument().then((helpDocument) => {
      this.setState({ helpDocument })
    })
  }

  hot_keys = {
    '?': {
      handler: () => this.handleOpen(),
      priority: 1,
    },
  }

  handleOpen() {
    this.setState({ isOpen: true })
  }

  handleClose() {
    this.setState({ isOpen: false })
  }

  getTrigger() {
    return (
      <Button icon onClick={() => this.handleOpen()}>
        <Icon name="help" />
      </Button>
    )
  }

  getContent() {
    const { helpDocument } = this.state
    return (
      <Modal.Description>
        <ReactMarkdown children={helpDocument} />
      </Modal.Description>
    )
  }

  render() {
    const { isOpen } = this.state
    return (
      <Modal
        open={isOpen}
        trigger={this.getTrigger()}
        onClose={() => this.handleClose()}
      >
        <Modal.Header>Aruba UXI Support Portal Help</Modal.Header>
        <Modal.Content>{this.getContent()}</Modal.Content>
      </Modal>
    )
  }
}

export default hotkeys(HelpModal)

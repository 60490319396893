import React, { Fragment, PureComponent } from 'react'
import { Form, List, Label, Segment, Icon, Pagination } from 'semantic-ui-react'
import { hotkeys } from 'react-keyboard-shortcuts'
import SelectableList from 'components/SelectableList'
import PortalHeader from 'components/PortalHeader'

const ICON_MAP = {
  sensor: 'life ring',
  customer: 'building',
  user: 'user',
}

class SearchView extends PureComponent {
  hot_keys = {
    return: {
      priority: 1,
      handler: () => {
        const { onSubmitActiveResult } = this.props
        onSubmitActiveResult()
      },
    },
  }

  getIconColour(entity) {
    const { metadata } = entity
    switch (entity.type) {
      case 'sensor':
        return metadata.customer && metadata.customer.name ? 'black' : 'orange'
      default:
        return 'black'
    }
  }

  getTitle(entity) {
    const { metadata } = entity
    switch (entity.type) {
      case 'customer':
        return <Fragment>{metadata.name}</Fragment>
      case 'sensor':
        return <Fragment>{metadata.serial}</Fragment>
      case 'user':
        return (
          <Fragment>
            {metadata.name} {metadata.surname}
          </Fragment>
        )
      default:
        throw new Error(`Unknown entity type ${entity.type}`)
    }
  }

  getMacAddress(metadata, type) {
    if (!metadata.mac_addresses) return

    return `${type}: ${metadata.mac_addresses[type]}`
  }

  getDescription(entity) {
    const { metadata } = entity
    switch (entity.type) {
      case 'customer':
        return <Fragment>{metadata.type || 'UNKNOWN'}</Fragment>
      case 'sensor':
        return (
          <Fragment>
            <Fragment>
              {(metadata.customer && metadata.customer.name) || 'NO CUSTOMER'} (
              {metadata.serial})
            </Fragment>
            {metadata.mac_addresses && (
              <Label className="float-right">
                {this.getMacAddress(metadata, 'eth')}
              </Label>
            )}
            {metadata.mac_addresses && (
              <Label className="float-right">
                {this.getMacAddress(metadata, 'wifi')}
              </Label>
            )}
          </Fragment>
        )
      case 'user':
        return <Fragment>{metadata.email}</Fragment>
      default:
        throw new Error(`Unknown entity type ${entity.type}`)
    }
  }

  getItem(result, isSelected) {
    return (
      <Fragment>
        {isSelected && <Icon name="angle right" />}
        <Icon
          size="big"
          color={this.getIconColour(result)}
          name={ICON_MAP[result.type]}
        />
        <List.Content>
          <List.Header as="a">{this.getTitle(result)}</List.Header>
          <List.Description>{this.getDescription(result)}</List.Description>
        </List.Content>
      </Fragment>
    )
  }

  render() {
    const {
      query,
      filter,
      results,
      isLoading,
      searchOptions,
      activePage,
      totalPages,
      onChangeQuery,
      onChangeFilter,
      onChangeActivePage,
      onClick,
      onSelect,
    } = this.props
    return (
      <Fragment>
        <PortalHeader>
          <Form>
            <Form.Group className="header-form" widths="16">
              <Form.Input
                autoFocus
                tabIndex="1"
                placeholder="Search Query"
                width="12"
                value={query}
                loading={isLoading}
                onChange={(e) => onChangeQuery(e.target.value)}
              />
              <Form.Select
                fluid
                tabIndex="2"
                placeholder="Filter Entities"
                options={searchOptions}
                value={filter}
                onChange={(e, v) => onChangeFilter(v.value)}
                width="4"
              />
            </Form.Group>
          </Form>
        </PortalHeader>
        <Segment>
          {results.length === 0 && query && !isLoading && 'No Results'}
          {results.length !== 0 && (
            <Fragment>
              <SelectableList
                items={results}
                getItem={(...args) => this.getItem(...args)}
                onClick={(i) => onClick(i)}
                onSelect={(i) => onSelect(i)}
              />
              <Pagination
                activePage={activePage}
                onPageChange={(_, { activePage }) =>
                  onChangeActivePage(activePage)
                }
                siblingRange="6"
                totalPages={totalPages}
              />
            </Fragment>
          )}
        </Segment>
      </Fragment>
    )
  }
}

export default hotkeys(SearchView)

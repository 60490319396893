const ERROR_INVALID_REQUESTER_EMAIL =
  'You must specify a valid email address for yourself.'
const ERROR_MIN_DEVICES = 'You must specify at least one device to be moved.'
const ERROR_INVALID_DEVICE_MAC =
  'You must specify a valid MAC address for sensor {DEVICE}.'
const ERROR_MIN_USERS =
  'You must specify at least one user to add to the customer.'
const ERROR_INVALID_USER_EMAIL =
  'You must specify a valid email address for {USER}.'
const ERROR_INVALID_FARSIDE_CONTENTS =
  'You must specify valid .farside content.'
const ERROR_INVALID_EXCEL_CONTENTS = 'You must specify a valid .xlsx file.'
const ISSUE_SEVERITY_ERROR = 'ERROR'
const ISSUE_SEVERITY_WARNING = 'WARNING'
const ERROR_INVALID_DEVICE_UUID_LIST =
  'The devices must be specified as a comma-separated list of device UUIDs.'

export {
  ERROR_INVALID_REQUESTER_EMAIL,
  ERROR_MIN_DEVICES,
  ERROR_INVALID_DEVICE_MAC,
  ERROR_MIN_USERS,
  ERROR_INVALID_USER_EMAIL,
  ERROR_INVALID_FARSIDE_CONTENTS,
  ERROR_INVALID_EXCEL_CONTENTS,
  ISSUE_SEVERITY_ERROR,
  ISSUE_SEVERITY_WARNING,
  ERROR_INVALID_DEVICE_UUID_LIST,
}

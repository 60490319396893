import React from 'react'

import { Button } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom'

import { useAuth0 } from 'react-auth0-wrapper.js'
import css from './Menu.module.scss'

const Menu = () => {
  const { logout } = useAuth0()

  return (
    <div className={css.container}>
      <div className={css.leftContainer}>
        <Link to="/">
          <div className={css.title}>
            <img className={css.logo} src="/logo.svg" alt="Logo" />
            <div className={css.titleText}>Aruba UXI Support</div>
          </div>
        </Link>
        <div className={css.menuDropDownContainer}>
          <NavLink to="#">Requests</NavLink>
          <div className={css.dropDownOptions}>
            <NavLink to="/requests/new_customer" className={css.menuItem}>
              New Customer
            </NavLink>
            <NavLink to="/requests/extend_trial" className={css.menuItem}>
              Extend Trial
            </NavLink>
            <NavLink to="/requests/new_users" className={css.menuItem}>
              New Users
            </NavLink>
            <NavLink to="/requests/link_users" className={css.menuItem}>
              Link Users
            </NavLink>
            <NavLink to="/requests/move_devices" className={css.menuItem}>
              Move Devices
            </NavLink>
            <NavLink to="/requests/captive_portal" className={css.menuItem}>
              Captive Portal
            </NavLink>
            <NavLink to="/requests/customer_hierarchy" className={css.menuItem}>
              Hierarchy
            </NavLink>
          </div>
        </div>
        <NavLink to="/queries" className={css.menuItem}>
          Queries
        </NavLink>
      </div>
      <div className={css.rightContainer}>
        <Button className={css.logout} onClick={logout}>
          Logout
        </Button>
      </div>
    </div>
  )
}

export default Menu

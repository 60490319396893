import React, { Component } from 'react'
import { List } from 'semantic-ui-react'
import { hotkeys } from 'react-keyboard-shortcuts'

class SelectableList extends Component {
  constructor(props) {
    super(props)
    const { items, onSelect } = this.props
    if (items.length > 0) {
      const selectedItem = items[0]
      onSelect(selectedItem)
      this.state = { selectedItem }
    } else {
      this.state = { selectedItem: {} }
    }
  }

  hot_keys = {
    up: {
      priority: 1,
      handler: () => {
        const { items } = this.props
        const { selectedItem } = this.state
        const nextIndex = Math.max(items.indexOf(selectedItem) - 1, 0)
        this.setState({ selectedItem: items[nextIndex] })
        return false
      },
    },
    down: {
      priority: 1,
      handler: () => {
        const { items } = this.props
        const { selectedItem } = this.state
        const nextIndex = Math.min(
          items.indexOf(selectedItem) + 1,
          items.length - 1
        )
        this.setState({ selectedItem: items[nextIndex] })
        return false
      },
    },
  }

  componentDidUpdate(prevProps) {
    const { items: oldItems } = prevProps
    const { onSelect, items } = this.props
    if (items.length > 0 && items !== oldItems) {
      const selectedItem = items[0]
      onSelect(selectedItem)
      this.setState({ selectedItem })
    }
  }

  handleClick(item) {
    const { onClick } = this.props
    this.setState({ selectedItem: item })
    onClick(item)
  }

  render() {
    const { selectedItem } = this.state
    const { getItem, items, enabled } = this.props
    const listItems = items.map((item) => {
      const isSelected = item === selectedItem
      const itemContent = getItem(item, isSelected)
      return (
        <List.Item
          key={item.key}
          onClick={() => enabled && this.handleClick(item)}
        >
          {itemContent}
        </List.Item>
      )
    })
    return (
      <List divided relaxed>
        {listItems}
      </List>
    )
  }
}

SelectableList.defaultProps = { enabled: true }

export default hotkeys(SelectableList)

import React, { Component } from 'react'
import HealthAlert from './HealthAlert'
import { Label, Image, Container } from 'semantic-ui-react'

class HealthResult extends Component {
  render() {
    const { host, value } = this.props
    const STATUS_IMG = {
      ok: 'cape_smile.png',
      warning: 'cape_maybe.png',
      error: 'cape_sad.png',
    }
    const img = '/' + STATUS_IMG[value.health]

    return (
      <Container fluid={true}>
        <Label size="big" className="host_name">
          {host}
        </Label>
        <Image src={img} className="host_status" />
        <div className="clear" />
        {value.alerts &&
          value.alerts.map((alert, index) => (
            <HealthAlert key={index} data={alert} />
          ))}
      </Container>
    )
  }
}

export default HealthResult

import React, { Fragment, Component } from 'react'
import { Table, Form, Icon } from 'semantic-ui-react'
import ExpandableTable from 'components/ExpandableTable'
import UserDetailsController from 'summary/UserDetailsController'
import PropTypes from 'prop-types'
import TimeAgo from 'react-timeago'
import client from 'client'
import { search } from 'util'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'

const URL_PROPS_QUERY_CONFIG = {
  usersFilter: { type: UrlQueryParamTypes.string },
}

class UsersController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      isLoading: false,
    }
  }

  static propTypes = {
    usersFilter: PropTypes.string,
    onChangeUsersFilter: PropTypes.func.isRequired,
  }

  static defaultProps = {
    usersFilter: '',
  }

  componentWillMount() {
    const { customerUuid } = this.props
    this.setState({ isLoading: true }, () => {
      client.listCustomerUsers(customerUuid).then((users) => {
        this.setState({ users, isLoading: false })
      })
    })
  }

  getRow = (user, isSelected, onExpand) => (
    <Table.Row onClick={onExpand}>
      <Table.Cell>{isSelected && <Icon name="angle right" />}</Table.Cell>
      <Table.Cell>
        {user.name} {user.surname}
      </Table.Cell>
      <Table.Cell singleLine>{user.email}</Table.Cell>
      <Table.Cell singleLine>
        <TimeAgo date={user.date_created} />
      </Table.Cell>
    </Table.Row>
  )

  getExpansion = (user) => (
    <Table.Row>
      <Table.Cell colSpan="4">
        <UserDetailsController uid={user.uid} />
      </Table.Cell>
    </Table.Row>
  )

  getHeader = () => (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell collapsing />
        <Table.HeaderCell singleLine>Name</Table.HeaderCell>
        <Table.HeaderCell singleLine>Email</Table.HeaderCell>
        <Table.HeaderCell singleLine>Created On</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  )

  render() {
    const { usersFilter, onChangeUsersFilter } = this.props
    const { isLoading, users } = this.state
    const filteredUsers = search(
      users,
      ['name', 'surname', 'email', 'uid', 'date_created'],
      usersFilter
    )
    return (
      <Fragment>
        <Form>
          <Form.Input
            autoFocus
            tabIndex="1"
            placeholder="Search Query"
            value={usersFilter}
            loading={isLoading}
            onChange={(e) => onChangeUsersFilter(e.target.value)}
          />
        </Form>
        {!isLoading && (
          <ExpandableTable
            rows={filteredUsers}
            getRow={(...args) => this.getRow(...args)}
            getHeader={() => this.getHeader()}
            getExpansion={(row) => this.getExpansion(row)}
            idLookup={(user) => user.uid}
          />
        )}
      </Fragment>
    )
  }
}

export default addUrlProps({ urlPropsQueryConfig: URL_PROPS_QUERY_CONFIG })(
  UsersController
)

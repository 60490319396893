import React, { PureComponent } from 'react'
import { Button } from 'semantic-ui-react'
import { hotkeys } from 'react-keyboard-shortcuts'

class BackButton extends PureComponent {
  hot_keys = {
    // TODO: This is commented out because I have not been able to cancel the
    // event propagation from the search input. This is not an unsolvable problem :P
    // backspace: {
    //   priority: 1,
    //   handler: () => this.props.onClick()
    // }
  }
  render() {
    const { children, ...other } = this.props
    return <Button {...other}>{children}</Button>
  }
}

export default hotkeys(BackButton)

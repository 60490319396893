import React from 'react'
import { Button } from 'semantic-ui-react'
import { useAuth0 } from 'react-auth0-wrapper.js'

import css from './LoginController.module.scss'

const LoginController = ({ loading }) => {
  const { loginWithRedirect } = useAuth0()

  return (
    <div className={css.container}>
      {loading && <div className={css.ldsHourglass} />}
      {!loading && (
        <div className={css.innerContainer}>
          <div className={css.title}>
            <img className={css.logo} src="/logo.svg" alt="Logo" />
            <div className={css.titleText}>Aruba UXI Support</div>
          </div>
          <Button onClick={loginWithRedirect} primary>
            Login
          </Button>
        </div>
      )}
    </div>
  )
}

export default LoginController

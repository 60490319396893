import validator from 'validator'

export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export const validateEmail = (email) => {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateMacAddress = (mac) => {
  var re = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})/
  return re.test(String(mac))
}

export const validateDeviceList = (devices) => {
  var devicesList = devices.split(',')
  for (let i = 0; i < devicesList.length; i++) {
    if (!validator.isUUID(devicesList[i])) {
      return false
    }
  }
  return true
}

export const camelCaseToSnakeCase = (text) => {
  return text
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}

export const convertObjectToSnakeCaseKeys = (object) => {
  var mutated = {}
  Object.keys(object).forEach((key) => {
    mutated[camelCaseToSnakeCase(key)] = object[key]
  })
  return mutated
}

export class Stack {
  constructor() {
    this.items = []
    this.count = 0
  }

  getLength() {
    return this.count
  }

  push(item) {
    this.items.push(item)
    this.count = this.count + 1
  }

  pop() {
    if (this.count > 0) {
      this.count = this.count - 1
    }

    return this.items.pop()
  }

  peek(n = 0) {
    return this.items.slice(-1)[n]
  }
}

export function dig(target, keys, options) {
  let digged = target
  for (const key of keys.split('.')) {
    digged = digged[key]
    if (typeof digged === 'undefined' || digged === null) {
      if (options.raise === true) {
        throw Error(`No such key ${key}.`)
      }
      return undefined
    }
  }
  return digged
}

export function search(data, keys, query) {
  const matches = new Set()
  for (let entry of data) {
    for (let key of keys) {
      if (entry[key].match(new RegExp(query, 'i'))) {
        matches.add(entry)
      }
    }
  }

  return Array.from(matches)
}

function isLeaf(node) {
  return typeof node.value !== 'object' || node.problem
}

function getPath(key, backref) {
  const path = []
  let memo = key
  while (memo !== 'root') {
    path.push(memo)
    memo = backref.get(memo)
  }
  path.reverse()
  return path
}

export function getLeaves(root) {
  if (Object.keys(root).length === 0) {
    return []
  }
  const stack = new Stack()
  const leaves = new Stack()
  const backref = new Map()

  stack.push(['root', root])

  while (stack.getLength() > 0) {
    const [key, node] = stack.pop()
    // TODO: Fix events / event stats on the backend.
    if (key === 'event_stats') {
      continue
    }

    if (isLeaf(node)) {
      const path = getPath(key, backref)
      leaves.push([key, node, path])
    } else {
      const entries = Object.entries(node.value)
      for (let entry of entries) {
        backref.set(entry[0], key)
        stack.push(entry)
      }
    }
  }

  return leaves.items
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let encoded = reader.result.replace(/^data:(.*;base64,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = (error) => reject(error)
  })
}

import FormController from 'utils/forms/controllers/init'
import {
  ERROR_INVALID_REQUESTER_EMAIL,
  ERROR_MIN_USERS,
  ERROR_INVALID_USER_EMAIL,
} from 'utils/forms/errors'
import { validateEmail, convertObjectToSnakeCaseKeys } from 'util'
import client from 'client'

class LinkUsersFormController extends FormController {
  validate = () => {
    const { request, linkedUsers } = this.form.state

    let messages = []
    let validated = true

    if (!validateEmail(request.email)) {
      messages.push(this.makeValidationError(ERROR_INVALID_REQUESTER_EMAIL))
      validated = false
    }

    if (!linkedUsers || linkedUsers.length === 0) {
      messages.push(this.makeValidationError(ERROR_MIN_USERS))
      validated = false
    } else {
      Object.values(linkedUsers).forEach((user) => {
        if (!validateEmail(user.email)) {
          messages.push(
            this.makeValidationError(
              ERROR_INVALID_USER_EMAIL.replace('{USER}', `${user.email}`)
            )
          )
          validated = false
        }
      })
    }

    return { validated, messages }
  }

  submit = () => {
    const { request, customer, linkedUsers } = this.form.state
    const data = {
      linked_users: Object.values(linkedUsers).map((user) =>
        convertObjectToSnakeCaseKeys(user)
      ),
      name: customer.name,
    }

    return client.requestWorkflow(request, 'link_users', data)
  }
}

export default LinkUsersFormController

import React, { Component } from 'react'
import { Container, Form, Button, Header } from 'semantic-ui-react'
import SelectCustomerForm from 'requests/forms/SelectCustomerForm'
import RequesterForm from 'requests/forms/RequesterForm'
import AssignUserForm from 'requests/forms/AssignUserForm'
import NewUsersFormController from 'utils/forms/controllers/NewUsersFormController'
import client from 'client'
import { renderMessages } from 'utils/forms/messages'

class NewUsersController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      request: null,
      customer: null,
      users: null,
      isSending: false,
    }
    this.controller = new NewUsersFormController(this)
  }

  componentWillMount() {
    client.retrieveCustomerNames().then((customers) => {
      this.setState({ customers })
    })
  }

  onRequestChanged = (request) => {
    this.setState({ request })
  }

  onCustomerChanged = (customer) => {
    this.setState({ customer })
  }

  onUsersChanged = (users) => {
    this.setState({ users })
  }

  render() {
    const { isSending, messages, customer, customers } = this.state
    const hasError = messages.some((message) => message.type === 'error')
    const hasWarning = messages.some((message) => message.type === 'warning')
    const isSuccess = !hasError && !hasWarning

    return (
      <Container>
        <Header as="h1" dividing>
          Create New Users
        </Header>
        <Form
          error={hasError}
          warning={hasWarning}
          success={isSuccess}
          onSubmit={this.controller.handleSubmit.bind(this)}
          loading={isSending}
        >
          {renderMessages(messages)}

          <Header as="h3" dividing>
            CUSTOMER
          </Header>
          <SelectCustomerForm
            onChange={this.onCustomerChanged}
            name={customer}
            customers={customers}
          />

          <Header as="h3" dividing>
            USERS
          </Header>
          <AssignUserForm onChange={this.onUsersChanged} collectNames={true} />

          <Header as="h3" dividing>
            REQUEST DETAILS
          </Header>
          <RequesterForm onChange={this.onRequestChanged} />

          <Button type="submit" disabled={isSending}>
            Submit
          </Button>
        </Form>
      </Container>
    )
  }
}

export default NewUsersController

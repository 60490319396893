import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import SystemStatusModal from 'health/SystemStatusModal'
import HelpModal from 'HelpModal'

class PortalHeader extends Component {
  render() {
    const { children } = this.props
    return (
      <div className="portal-header-container">
        <div className="portal-header-left">{children}</div>
        <div className="portal-header-right">
          <Button.Group>
            <SystemStatusModal />
            <HelpModal />
          </Button.Group>
        </div>
      </div>
    )
  }
}

export default PortalHeader

import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import QueryDescription from 'queries/QueryDescription'
import QueryParameters from 'queries/QueryParameters'
import QueryResult from 'queries/QueryResult'
import client from 'client'

class QueryController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: {},
      loading: false,
    }
  }

  componentDidMount() {
    const { query } = this.props
    this.setState({ query })
  }

  handleSubmit(model) {
    const { key } = this.props.query
    this.setState({ loading: true }, () => {
      client
        .runQuery(key, model)
        .then((result) => {
          console.log(result)
          this.setState({ result, loading: false })
        })
        .catch((error) => {
          this.setState({ result: {}, loading: false })
        })
    })
  }

  render() {
    const { query } = this.props
    const { result, loading } = this.state
    return (
      <Segment>
        <QueryDescription query={query} />
        <QueryParameters query={query} onSubmit={(m) => this.handleSubmit(m)} />
        <QueryResult loading={loading} result={result} />
      </Segment>
    )
  }
}

export default QueryController

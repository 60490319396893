import FormController from 'utils/forms/controllers/init'
import { ERROR_INVALID_REQUESTER_EMAIL } from 'utils/forms/errors'
import { validateEmail } from 'util'
import client from 'client'

class ExtendTrialFormController extends FormController {
  validate = () => {
    const { request } = this.form.state

    let messages = []
    let validated = true

    if (!validateEmail(request.email)) {
      messages.push(this.makeValidationError(ERROR_INVALID_REQUESTER_EMAIL))
      validated = false
    }

    return { validated, messages }
  }

  submit = () => {
    const { request, customer, expiry } = this.form.state
    const data = {
      expiry: expiry.date,
      name: customer.name,
    }
    return client.requestWorkflow(request, 'extend_trial', data)
  }
}

export default ExtendTrialFormController

import axios from 'axios'
import helpDocumentUrl from 'help.md'
import { toast } from 'react-semantic-toasts/build/toast'

const DEFAULT_ENDPOINT = 'http://localhost:5000'
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || DEFAULT_ENDPOINT

class Client {
  constructor(endpoint) {
    this.instance = axios.create({ baseURL: endpoint })
    this.instance.interceptors.request.use(
      (request) => {
        if (!this.jwt) {
          throw new Error('Client not initialised with JWT')
        }

        request.headers.Authorization = `Bearer ${this.jwt}`
        return request
      },
      (error) => {
        if (axios.isCancel(error)) {
          return
        }
        toast({
          title: 'Something Went Wrong',
          type: 'error',
          time: 8000,
          description: error.toString(),
        })
        return Promise.reject(error)
      }
    )
    this.instance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) this.logout()
        if (axios.isCancel(error)) {
          return
        }
        let description = error.toString()
        if (error.response.data.errors && error.response.data.errors.issues) {
          let issue = error.response.data.errors.issues[0]
          description = `Error: ${issue.name} (value: ${issue.detail})`
        } else if (error.response.data && error.response.data.errors) {
          description = error.response.data.errors.toString()
        }
        toast({
          title: 'Something Went Wrong',
          type: 'error',
          time: 8000,
          description,
        })
        return Promise.reject(error)
      }
    )
  }
  initialise(jwt, logout) {
    this.jwt = jwt
    this.logout = logout
  }

  search(query, filter, activePage, options) {
    return this.instance
      .get('/search', {
        params: { query, filter, page: activePage },
        ...options,
      })
      .then((response) => {
        return response.data
      })
  }

  retrieveServicesHealth() {
    return this.instance.get('/services_health').then((response) => {
      return response.data
    })
  }

  listQueries() {
    return this.instance.get('/queries').then((response) => {
      return response.data
    })
  }

  listCustomerSensors(customerUuid) {
    return this.instance
      .get(`/customers/${customerUuid}/sensors/`)
      .then((response) => {
        return response.data
      })
  }

  listCustomerSsids(customerUuid) {
    return this.instance
      .get(`/customers/${customerUuid}/ssids/`)
      .then((response) => {
        return response.data
      })
  }

  listCustomerUsers(customerUuid) {
    return this.instance
      .get(`/customers/${customerUuid}/users/`)
      .then((response) => {
        return response.data
      })
  }

  retrieveCustomerNamesWithExpiry() {
    const params = { fields: 'name,trial_end_date' }
    return this.instance.get('/customers', { params }).then((response) => {
      return response.data.map(function (datum) {
        return {
          text: datum.name,
          value: datum.name,
          expiry: datum.trial_end_date,
        }
      })
    })
  }

  retrieveCustomerNamesWithUuid() {
    const params = { fields: 'name,uuid' }
    return this.instance.get('/customers', { params }).then((response) => {
      return response.data.map(function (datum) {
        return { text: datum.name, value: datum.name, uuid: datum.uuid }
      })
    })
  }

  retrieveCustomerNames() {
    const params = { fields: 'name' }
    return this.instance.get('/customers', { params }).then((response) => {
      return response.data.map(function (datum) {
        return { text: datum.name, value: datum.name }
      })
    })
  }

  retrieveCustomer(id) {
    return this.instance.get(`/customers/${id}`).then((response) => {
      return response.data
    })
  }

  requestWorkflow(request, type, data) {
    const json = {
      requester_email: request.email,
      requester_comment: request.comment,
      data: data,
      type: type,
    }

    return this.instance.post('/workflows/', json).then((response) => {
      return response.data
    })
  }

  retrieveUser(id) {
    return this.instance.get(`/users/${id}`).then((response) => {
      return response.data
    })
  }

  runQuery(key, parameters) {
    return this.instance
      .get(`/queries/${key}`, { params: parameters })
      .then((response) => {
        return response.data
      })
  }

  retrieveHelpDocument() {
    return axios.get(helpDocumentUrl).then((response) => {
      return response.data
    })
  }
}

export default new Client(ENDPOINT)

import React, { useEffect, useState } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { __RouterContext as RouterContext } from 'react-router'
import { RouterToUrlQuery } from 'react-url-query'
import { SemanticToastContainer } from 'react-semantic-toasts'
import { Container } from 'semantic-ui-react'

import { useAuth0 } from 'react-auth0-wrapper.js'
import Client from 'client'

import LoginController from 'login'
import Menu from 'components/Menu'
import SearchController from 'search/SearchController'
import QueriesController from 'queries/QueriesController'
import NewCustomerController from 'requests/controllers/NewCustomerController'
import NewUsersController from 'requests/controllers/NewUsersController'
import MoveDevicesController from 'requests/controllers/MoveDevicesController'
import ExtendTrialController from 'requests/controllers/ExtendTrialController'
import CustomerSummaryController from 'summary/CustomerSummaryController'
import LinkUsersController from 'requests/controllers/LinkUsersController'
import CaptivePortalController from 'requests/controllers/CaptivePortalController'
import CustomerHierarchyController from 'requests/controllers/CustomerHierarchyController'
import SystemStatusProvider from 'health/SystemStatusProvider'

const App = () => {
  const { isAuthenticated, loading, jwt, logout } =
    useAuth0()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (isAuthenticated && !loading && jwt) {
      if (!ready) {
        Client.initialise(jwt, logout)
        setReady(true)
      }
    } else if (ready) {
      setReady(false)
    }
  }, [isAuthenticated, loading, jwt, logout, ready])

  if (!isAuthenticated) {
    return <LoginController loading={loading} />
  }

  return (
    <Container className="app">
      {ready && (
        <SystemStatusProvider>
          <Router>
            <RouterToUrlQuery routerContext={RouterContext}>
              <div>
                <Menu />
                <Switch>
                  <Route exact path="/" component={SearchController} />
                  <Route path="/queries" component={QueriesController} />
                  <Route
                    path="/requests/new_customer"
                    component={NewCustomerController}
                  />
                  <Route
                    path="/requests/new_users"
                    component={NewUsersController}
                  />
                  <Route
                    path="/requests/move_devices"
                    component={MoveDevicesController}
                  />
                  <Route
                    path="/requests/extend_trial"
                    component={ExtendTrialController}
                  />
                  <Route
                    path="/requests/link_users"
                    component={LinkUsersController}
                  />
                  <Route
                    path="/requests/captive_portal"
                    component={CaptivePortalController}
                  />
                  <Route
                    path="/requests/customer_hierarchy"
                    component={CustomerHierarchyController}
                  />
                  <Route
                    path="/customers/:uuid"
                    component={CustomerSummaryController}
                  />
                </Switch>
              </div>
            </RouterToUrlQuery>
          </Router>
        </SystemStatusProvider>
      )}
      <SemanticToastContainer />
    </Container>
  )
}

export default App

import React from 'react'
import { Message } from 'semantic-ui-react'

const MESSAGE_STRUCT_SUCCESS = {
  type: 'success',
  title: 'Request Queued',
  text: 'Please wait for feedback from the support engineer.',
}

function renderMessages(messages) {
  return messages.map((message, index) => {
    const isError = message.type === 'error'
    const isWarning = message.type === 'warning'
    const isSuccess = !isError && !isWarning
    const key = `message-${index}`
    return (
      <Message
        key={key}
        error={isError}
        warning={isWarning}
        success={isSuccess}
        header={message.title}
        content={message.text}
      />
    )
  })
}

export { MESSAGE_STRUCT_SUCCESS, renderMessages }

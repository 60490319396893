import React, { Component, Fragment } from 'react'
import { Icon, Table, Form } from 'semantic-ui-react'
import ExpandableTable from 'components/ExpandableTable'
import SensorStatusController from 'summary/SensorStatusController'
import client from 'client'
import PropTypes from 'prop-types'
import { search } from 'util'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'

const URL_PROPS_QUERY_CONFIG = {
  sensorsFilter: { type: UrlQueryParamTypes.string },
}

class SensorsController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sensors: [],
      isLoading: false,
      expandedSensors: new Set(),
      selectedSensor: {},
    }
  }

  static propTypes = {
    sensorsFilter: PropTypes.string,
    onChangeSensorsFilter: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sensorsFilter: '',
  }

  componentDidMount() {
    this.fetchSensors()
  }

  fetchSensors() {
    const { customerUuid } = this.props
    this.setState({ isLoading: true }, () => {
      client.listCustomerSensors(customerUuid).then((sensors) => {
        this.setState({ sensors, selectedSensor: sensors[0], isLoading: false })
      })
    })
  }

  getRow(sensor, isSelected, onExpand) {
    return (
      <Table.Row onClick={onExpand}>
        <Table.Cell>{isSelected && <Icon name="angle right" />}</Table.Cell>
        <Table.Cell>{sensor.serial}</Table.Cell>
        <Table.Cell singleLine>{sensor.uuid}</Table.Cell>
        <Table.Cell singleLine>{sensor.platform}</Table.Cell>
      </Table.Row>
    )
  }

  getExpansion(sensor) {
    return (
      <Table.Row>
        <Table.Cell colSpan="4">
          <SensorStatusController uuid={sensor.uuid} />
        </Table.Cell>
      </Table.Row>
    )
  }

  getHeader() {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing />
          <Table.HeaderCell singleLine>Serial</Table.HeaderCell>
          <Table.HeaderCell singleLine>UUID</Table.HeaderCell>
          <Table.HeaderCell singleLine>Platform</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    )
  }

  render() {
    const { sensorsFilter, onChangeSensorsFilter } = this.props
    const { sensors, isLoading } = this.state
    const filteredSensors = search(
      sensors,
      ['serial', 'uuid', 'platform'],
      sensorsFilter
    )
    return (
      <Fragment>
        <Form>
          <Form.Input
            autoFocus
            tabIndex="1"
            placeholder="Search Query"
            value={sensorsFilter}
            loading={isLoading}
            onChange={(e) => onChangeSensorsFilter(e.target.value)}
          />
        </Form>
        {!isLoading && (
          <ExpandableTable
            rows={filteredSensors}
            getHeader={() => this.getHeader()}
            getRow={this.getRow.bind(this)}
            getExpansion={(...args) => this.getExpansion(...args)}
          />
        )}
      </Fragment>
    )
  }
}

export default addUrlProps({ urlPropsQueryConfig: URL_PROPS_QUERY_CONFIG })(
  SensorsController
)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'debounce'
import { CancelToken, isCancel } from 'axios'
import { toast } from 'react-semantic-toasts/build/toast'
import { withRouter } from 'react-router'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import { dig } from 'util'
import client from 'client'
import SearchView from 'search/SearchView'

const SEARCH_OPTIONS = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Sensors',
    value: 'sensor',
  },
  {
    text: 'Customer',
    value: 'customer',
  },
  {
    text: 'User',
    value: 'user',
  },
]

const URL_PROPS_CONFIG = {
  query: { type: UrlQueryParamTypes.string },
  filter: { type: UrlQueryParamTypes.string },
  activePage: { type: UrlQueryParamTypes.number },
}

class SearchController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      activeResult: {},
      totalPages: 0,
    }
  }

  static propTypes = {
    onChangeQuery: PropTypes.func,
    onChangeFilter: PropTypes.func,
    onChangeActivePage: PropTypes.func,
  }

  static defaultProps = {
    query: '',
    filter: 'all',
    activePage: 1,
  }

  componentDidMount() {
    this.refreshResults()
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel()
    }
  }

  handleSelect(item) {
    this.setState({ activeResult: item })
  }

  handleSubmitActiveResult() {
    const { activeResult } = this.state
    this.handleSubmit(activeResult)
  }

  handleSubmit(result) {
    const { history } = this.props
    try {
      const location = this.getLocation(result)
      history.push(location)
    } catch (e) {
      toast({
        title: 'Invalid Entity',
        type: 'error',
        time: 8000,
        description:
          'Entities not associated with customers are not yet supported' +
          e.toString(),
      })
    }
  }

  getLocation(entity) {
    let customerUuid
    let sensorSerial
    let userUuid
    switch (entity.type) {
      case 'customer':
        customerUuid = dig(entity, 'uuid', { raise: true })
        return `/customers/${customerUuid}`
      case 'sensor':
        customerUuid = dig(entity, 'metadata.customer.uuid', {
          raise: true,
        })
        sensorSerial = dig(entity, 'metadata.serial', { raise: true })
        return `/customers/${customerUuid}?sensorsFilter=${sensorSerial}`
      case 'user':
        customerUuid = dig(entity, 'metadata.customer.uuid', {
          raise: true,
        })
        userUuid = dig(entity, 'uuid', { raise: true })
        return `/customers/${customerUuid}?usersFilter=${userUuid}`
      default:
        throw new Error(`Unknown entity type ${entity.type}`)
    }
  }

  _doUnifiedSearch(query, filter, activePage) {
    client
      .search(query, filter, activePage, { cancelToken: this.source.token })
      .then((payload) => {
        const { pages: totalPages } = payload.index
        const results = payload.results.map((result) => {
          result.key = `${result.type}-${result.uuid}`
          return result
        })
        this.setState({ results, totalPages, isLoading: false })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        if (isCancel(error)) {
          return false
        }
      })
  }

  _refreshResults(query, filter, activePage) {
    if (query === '') {
      return
    }
    if (this.source) {
      this.source.cancel()
    }
    this.setState({ isLoading: true }, () => {
      this.source = CancelToken.source()
      this._doUnifiedSearch(query, filter, activePage)
    })
  }

  refreshResults = debounce(() => {
    const { query, filter, activePage } = this.props
    this._refreshResults(query, filter, activePage)
  }, 200)

  handleChangeQuery(query) {
    const { onChangeQuery, onChangeActivePage, filter } = this.props
    const activePage = 1

    onChangeQuery(query)
    onChangeActivePage(activePage)
    if (query === '') {
      this.setState({ results: [], activeResult: {} })
      return
    }
    this.refreshResults(query, filter, activePage)
  }

  handleChangeFilter(filter) {
    const { onChangeFilter, query, activePage } = this.props
    onChangeFilter(filter)
    this.refreshResults(query, filter, activePage)
  }

  handleChangeActivePage(activePage) {
    const { onChangeActivePage, query, filter } = this.props
    onChangeActivePage(activePage)
    this.refreshResults(query, filter, activePage)
  }

  render() {
    const { query, filter, activePage } = this.props
    const { results, totalPages, isLoading } = this.state
    return (
      <SearchView
        query={query}
        filter={filter}
        isLoading={isLoading}
        results={results}
        activePage={activePage}
        totalPages={totalPages}
        searchOptions={SEARCH_OPTIONS}
        onChangeFilter={(filter) => this.handleChangeFilter(filter)}
        onChangeQuery={(query) => this.handleChangeQuery(query)}
        onChangeActivePage={(activePage) =>
          this.handleChangeActivePage(activePage)
        }
        onClick={(item) => this.handleSubmit(item)}
        onSelect={(item) => this.handleSelect(item)}
        onSubmitActiveResult={(item) => this.handleSubmitActiveResult(item)}
      />
    )
  }
}

export default withRouter(
  addUrlProps({ urlPropsQueryConfig: URL_PROPS_CONFIG })(SearchController)
)

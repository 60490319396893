import React, { Component } from 'react'
import { Container, Form, Button, Header } from 'semantic-ui-react'
import SelectCustomerForm from 'requests/forms/SelectCustomerForm'
import RequesterForm from 'requests/forms/RequesterForm'
import CustomerHierarchyFormController from 'utils/forms/controllers/CustomerHierarchyFormController'
import client from 'client'
import { renderMessages } from 'utils/forms/messages'
import Dropzone from 'react-dropzone'
import { getBase64 } from 'util'

class CustomerHierarchyController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      request: null,
      customer: null,
      customers: [],
      file: null,
      contents: null,
      isSending: false,
    }
    this.controller = new CustomerHierarchyFormController(this)
  }

  componentWillMount() {
    client.retrieveCustomerNamesWithUuid().then((customers) => {
      this.setState({ customers })
    })
  }

  onRequestChanged = (request) => {
    this.setState({ request })
  }

  onContentsChanged = (event) => {
    this.setState({ contents: event.target.value })
  }

  onCustomerChanged = (customer) => {
    this.setState({ customer })
  }

  handleFileDrops = (accepted) => {
    const that = this
    getBase64(accepted[0]).then((base64) => {
      that.setState({ contents: base64, file: accepted[0].name })
    })
  }

  render() {
    const { isSending, messages, customer, file, customers } = this.state
    const hasError = messages.some((message) => message.type === 'error')
    const hasWarning = messages.some((message) => message.type === 'warning')
    const isSuccess = !hasError && !hasWarning

    const helpLabel = 'Drop your Excel spreadsheet here.'

    return (
      <Container>
        <Header as="h1" dividing>
          Update Customer Hierarchy
        </Header>
        <Form
          error={hasError}
          warning={hasWarning}
          success={isSuccess}
          onSubmit={this.controller.handleSubmit.bind(this)}
          loading={isSending}
        >
          {renderMessages(messages)}

          <Header as="h3" dividing>
            CUSTOMER
          </Header>
          <SelectCustomerForm
            onChange={this.onCustomerChanged}
            name={customer}
            customers={customers}
          />

          <Header as="h3" dividing>
            HIERARCHY
          </Header>
          <Form.Field>
            <label>Excel spreadsheet</label>
            <Dropzone
              onDrop={(accepted, rejected) =>
                this.handleFileDrops(accepted, rejected)
              }
              multiple={false}
              maxSize={8000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  {file || helpLabel}

                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </Form.Field>

          <Header as="h3" dividing>
            REQUEST DETAILS
          </Header>
          <RequesterForm onChange={this.onRequestChanged} />

          <Button type="submit" disabled={isSending}>
            Submit
          </Button>
        </Form>
      </Container>
    )
  }
}

export default CustomerHierarchyController

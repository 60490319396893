import FormController from 'utils/forms/controllers/init'
import {
  ERROR_INVALID_REQUESTER_EMAIL,
  ERROR_MIN_USERS,
  ERROR_INVALID_USER_EMAIL,
  ERROR_MIN_DEVICES,
  ERROR_INVALID_DEVICE_MAC,
} from 'utils/forms/errors'
import {
  validateEmail,
  validateMacAddress,
  convertObjectToSnakeCaseKeys,
} from 'util'
import client from 'client'

class NewCustomerFormController extends FormController {
  validate = () => {
    const { request, users, devices, linkedUsers } = this.form.state

    let messages = []
    let validated = true

    if (!validateEmail(request.email)) {
      messages.push(this.makeValidationError(ERROR_INVALID_REQUESTER_EMAIL))
      validated = false
    }

    if (!users || users.length === 0) {
      messages.push(this.makeValidationError(ERROR_MIN_USERS))
      validated = false
    } else {
      Object.values(users).forEach((user) => {
        if (!validateEmail(user.email)) {
          messages.push(
            this.makeValidationError(
              ERROR_INVALID_USER_EMAIL.replace(
                '{USER}',
                `${user.firstName} ${user.lastName}`
              )
            )
          )
          validated = false
        }
      })
    }

    if (linkedUsers) {
      Object.values(linkedUsers).forEach((user) => {
        if (!validateEmail(user.email)) {
          messages.push(
            this.makeValidationError(
              ERROR_INVALID_USER_EMAIL.replace('{USER}', `${user.email}`)
            )
          )
          validated = false
        }
      })
    }

    if (!devices || devices.length === 0) {
      messages.push(this.makeValidationError(ERROR_MIN_DEVICES))
      validated = false
    } else {
      Object.values(devices).forEach((device) => {
        if (!validateMacAddress(device.macAddress)) {
          messages.push(
            this.makeValidationError(
              ERROR_INVALID_DEVICE_MAC.replace('{DEVICE}', `${device.serial}`)
            )
          )
          validated = false
        }
      })
    }

    return { validated, messages }
  }

  submit = () => {
    const { request, customer, users, linkedUsers, devices } = this.form.state
    const data = {
      users: Object.values(users).map((user) =>
        convertObjectToSnakeCaseKeys(user)
      ),
      devices: Object.values(devices).map((device) =>
        convertObjectToSnakeCaseKeys(device)
      ),
      linked_users: linkedUsers
        ? Object.values(linkedUsers).map((user) =>
            convertObjectToSnakeCaseKeys(user)
          )
        : null,
      name: customer.name,
      customer_type: customer.type,
      expiry: customer.expiry,
    }

    return client.requestWorkflow(request, 'create_customer', data)
  }
}

export default NewCustomerFormController

import React, { Component } from 'react'
import { Label, Container } from 'semantic-ui-react'

class HealthResult extends Component {
  render() {
    const { data } = this.props
    const json = JSON.parse(data)
    const alert_class = 'alert_' + json['alert_type']

    return (
      <Container fluid={true} className="host_alert">
        <Label size="small" content={json['timestamp']} />
        <Label size="small" className={alert_class} content={json['title']} />
      </Container>
    )
  }
}

export default HealthResult

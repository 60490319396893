import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'

class QueryDescription extends Component {
  render() {
    const { query } = this.props
    return (
      <div>
        <Header>{query.name}</Header>
        <p>{query.description}</p>
      </div>
    )
  }
}
export default QueryDescription

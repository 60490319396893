import React, { Component } from 'react'
import { Segment, Form } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'

const CUSTOMER_TYPES = [
  {
    text: 'Proof-of-concept',
    value: 'POC',
  },
  {
    text: 'Paying',
    value: 'PAYING',
  },
  {
    text: 'Internal',
    value: 'INTERNAL',
  },
  {
    text: 'Demo (External)',
    value: 'DEMO_EXTERNAL',
  },
  {
    text: 'Reseller',
    value: 'RESELLER',
  },
  {
    text: 'Promotion',
    value: 'PROMO',
  },
]

class NewCustomerForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      type: 'PAYING',
      expiry: new Date().toISOString().substring(0, 10),
    }
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleTypeChange = (event, { value }) => {
    this.setState({ type: value })
  }

  handleExpiryChange = (event, { value }) => {
    this.setState({ expiry: value })
  }

  handleChange(props, state) {
    const { onChange } = props
    const { name, type, expiry } = state
    onChange({ name, type, expiry })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleChange(this.props, this.state)
    }
  }

  isPOC = (type) => {
    return type === 'POC'
  }

  render() {
    const { name, type, expiry } = this.state
    const isPOC = this.isPOC(type)

    return (
      <Segment>
        <Form.Field>
          <label>Name</label>
          <input
            placeholder="Name"
            defaultValue={name}
            onChange={this.handleNameChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Type</label>
          <Form.Select
            placeholder="Select customer type"
            options={CUSTOMER_TYPES}
            defaultValue={type}
            onChange={this.handleTypeChange}
            required
          />
        </Form.Field>
        <Form.Field className={isPOC ? '' : 'hidden'}>
          <label>Trial Expiry Date</label>
          <DateInput
            placeholder="Select expiry date"
            value={expiry}
            dateFormat="YYYY-MM-DD"
            iconPosition="left"
            onChange={this.handleExpiryChange}
            hidden
            required={isPOC}
          />
        </Form.Field>
      </Segment>
    )
  }
}

export default NewCustomerForm

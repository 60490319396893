import React, { Component } from 'react'
import { Container, Form, Button, Header } from 'semantic-ui-react'
import SelectCustomerForm from 'requests/forms/SelectCustomerForm'
import RequesterForm from 'requests/forms/RequesterForm'
import SelectDateComponent from 'requests/components/SelectDateComponent'
import ExtendTrialFormController from 'utils/forms/controllers/ExtendTrialFormController'
import client from 'client'
import { renderMessages } from 'utils/forms/messages'

class ExtendTrialController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      request: null,
      customer: null,
      customers: [],
      expiry: null,
      isSending: false,
    }
    this.controller = new ExtendTrialFormController(this)
  }

  componentWillMount() {
    client.retrieveCustomerNamesWithExpiry().then((customers) => {
      this.setState({ customers })
    })
  }

  onRequestChanged = (request) => {
    this.setState({ request })
  }

  onCustomerChanged = (customer) => {
    const customerData = this.state.customers.find(
      (c) => c.text === customer.name
    )
    const expiry =
      customerData && customerData.expiry
        ? customerData.expiry.substring(0, 10)
        : null
    this.setState({ customer, expiry: { date: expiry } })
  }

  onExpiryChanged = (expiry) => {
    this.setState({ expiry })
  }

  render() {
    const { isSending, messages, customer, expiry, customers } = this.state
    const date = expiry ? expiry.date : null
    const hasError = messages.some((message) => message.type === 'error')
    const hasWarning = messages.some((message) => message.type === 'warning')
    const isSuccess = !hasError && !hasWarning

    return (
      <Container>
        <Header as="h1" dividing>
          Extend POC Trial
        </Header>
        <Form
          error={hasError}
          warning={hasWarning}
          success={isSuccess}
          onSubmit={this.controller.handleSubmit.bind(this)}
          loading={isSending}
        >
          {renderMessages(messages)}

          <Header as="h3" dividing>
            CUSTOMER
          </Header>
          <SelectCustomerForm
            onChange={this.onCustomerChanged}
            name={customer}
            customers={customers}
          />

          <Header as="h3" dividing>
            TRIAL DATE
          </Header>
          <SelectDateComponent date={date} onChange={this.onExpiryChanged} />

          <Header as="h3" dividing>
            REQUEST DETAILS
          </Header>
          <RequesterForm onChange={this.onRequestChanged} />

          <Button type="submit" disabled={isSending}>
            Submit
          </Button>
        </Form>
      </Container>
    )
  }
}

export default ExtendTrialController

import React, { PureComponent } from 'react'
import { Button, Icon, Modal, Segment } from 'semantic-ui-react'
import { SystemStatusContext } from './systemStatus'
import HealthStatus from './HealthStatus'

class SystemStatusModal extends PureComponent {
  getTrigger() {
    return (
      <Button icon labelPosition="left" color="red">
        <Icon name="warning circle" />
        System Issue
      </Button>
    )
  }

  getContent(results, isLoading) {
    return (
      <Segment>
        <HealthStatus isLoading={isLoading} results={results} />
      </Segment>
    )
  }

  getModal(results, isLoading) {
    const entries = Object.entries(results)
    const transform = (memo, [key, status]) => {
      if (status.health !== 'ok') {
        memo[key] = status
      }
      return memo
    }
    const negativeResults = entries.reduce(transform, {})
    if (Object.keys(negativeResults).length === 0) {
      return
    }
    return (
      <Modal trigger={this.getTrigger()}>
        <Modal.Header>System Status</Modal.Header>
        <Modal.Content>
          {this.getContent(negativeResults, isLoading)}
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    return (
      <SystemStatusContext.Consumer>
        {({ results, isLoading }) => this.getModal(results, isLoading)}
      </SystemStatusContext.Consumer>
    )
  }
}

export default SystemStatusModal

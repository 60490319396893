import FormController from 'utils/forms/controllers/init'
import {
  ERROR_INVALID_REQUESTER_EMAIL,
  ERROR_INVALID_EXCEL_CONTENTS,
} from 'utils/forms/errors'
import { validateEmail } from 'util'
import client from 'client'

class CustomerHierarchyFormController extends FormController {
  validate = () => {
    const { request, contents } = this.form.state

    let messages = []
    let validated = true

    if (!validateEmail(request.email)) {
      messages.push(this.makeValidationError(ERROR_INVALID_REQUESTER_EMAIL))
      validated = false
    }

    if (!contents) {
      messages.push(this.makeValidationError(ERROR_INVALID_EXCEL_CONTENTS))
      validated = false
    }

    return { validated, messages }
  }

  submit = () => {
    const { request, customer, contents } = this.form.state
    const data = {
      xlsx: contents,
      name: customer.name,
    }
    return client.requestWorkflow(request, 'update_customer_hierarchy', data)
  }
}

export default CustomerHierarchyFormController

import FormController from 'utils/forms/controllers/init'
import {
  ERROR_INVALID_REQUESTER_EMAIL,
  ERROR_INVALID_FARSIDE_CONTENTS,
} from 'utils/forms/errors'
import { validateEmail } from 'util'
import client from 'client'

class CaptivePortalFormController extends FormController {
  validate = () => {
    const { request, contents } = this.form.state

    let messages = []
    let validated = true

    if (!validateEmail(request.email)) {
      messages.push(this.makeValidationError(ERROR_INVALID_REQUESTER_EMAIL))
      validated = false
    }

    if (!contents) {
      messages.push(this.makeValidationError(ERROR_INVALID_FARSIDE_CONTENTS))
      validated = false
    }

    return { validated, messages }
  }

  submit = () => {
    const { request, name, customer, contents, affectedSSIDs } = this.form.state
    const data = {
      contents,
      name,
      customer_name: customer.name,
      ssids: affectedSSIDs,
    }
    return client.requestWorkflow(request, 'captive_portal', data)
  }
}

export default CaptivePortalFormController

import React, { Component } from 'react'
import { Form, Button } from 'semantic-ui-react'

class DeviceComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { ...props }
  }

  handleSerialChange = (event) => {
    this.setState({ serial: event.target.value })
  }

  handleMacAddressChange = (event) => {
    this.setState({ mac_address: event.target.value.toLowerCase() })
  }

  handleUpdateDevice = (props) => {
    const { onUpdateDevice, uid } = props
    const { serial, mac_address } = this.state

    onUpdateDevice(uid, serial, mac_address)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleUpdateDevice(this.props)
    }
  }

  handleRemoveDevice = (event, props) => {
    event.preventDefault()
    const { onRemoveDevice, uid } = props
    onRemoveDevice(uid)
  }

  render() {
    const { serial, mac_address } = this.state

    return (
      <Form.Group>
        <Form.Field>
          <label>Serial</label>
          <input
            placeholder="Serial"
            defaultValue={serial}
            onChange={this.handleSerialChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>MAC Address</label>
          <input
            placeholder="MAC Address"
            defaultValue={mac_address}
            onChange={this.handleMacAddressChange}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Actions</label>
          <Button
            content="Remove"
            negative
            onClick={(event) => this.handleRemoveDevice(event, this.state)}
          />
        </Form.Field>
      </Form.Group>
    )
  }
}

export default DeviceComponent

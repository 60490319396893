import React, { Component, Fragment } from 'react'
import client from 'client'
import LoadingText from 'components/LoadingText'
import { getLeaves } from 'util'
import { List } from 'semantic-ui-react'
import { SensorStatusItem } from './SensorStatusItem'

class SensorStatusController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: {},
      isLoading: false,
    }
  }

  handleUpdateStatus() {
    const { uuid } = this.props
    this.setState({ isLoading: true }, () => {
      client.runQuery('sensor_status', { sensor_uuid: uuid }).then((status) => {
        this.setState({ isLoading: false, status })
      })
    })
  }

  componentDidMount() {
    this.handleUpdateStatus()
  }

  componentDidUpdate(prevProps) {
    const { uuid: prevUuid } = prevProps
    const { uuid } = this.props
    if (uuid !== prevUuid) {
      this.handleUpdateStatus()
    }
  }

  render() {
    const { isLoading, status } = this.state
    const leaves = getLeaves(status)
    const items = leaves
      .filter(([_, entry]) => entry.status)
      .filter(([_, entry]) => entry.status !== 'ok')
      .map(([key, entry, path]) => (
        <SensorStatusItem key={path} entry={entry} path={path} />
      ))

    return (
      <Fragment>
        {isLoading && <LoadingText timeout={3} />}
        {!isLoading && items.length > 0 && <List>{items}</List>}
        {!isLoading && items.length === 0 && 'No Problems'}
      </Fragment>
    )
  }
}

export default SensorStatusController

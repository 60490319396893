import React, { Component } from 'react'
import { Container, Form, Button, Header, Dropdown } from 'semantic-ui-react'
import SelectCustomerForm from 'requests/forms/SelectCustomerForm'
import RequesterForm from 'requests/forms/RequesterForm'
import CaptivePortalFormController from 'utils/forms/controllers/CaptivePortalFormController'
import client from 'client'
import { renderMessages } from 'utils/forms/messages'

class CaptivePortalController extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      request: null,
      customer: null,
      customers: [],
      contents: null,
      affectedSSIDs: [],
      ssids: [],
      isSending: false,
    }
    this.controller = new CaptivePortalFormController(this)
  }

  componentWillMount() {
    client.retrieveCustomerNamesWithUuid().then((customers) => {
      this.setState({ customers })
    })
  }

  onRequestChanged = (request) => {
    this.setState({ request })
  }

  onContentsChanged = (event) => {
    this.setState({ contents: event.target.value })
  }

  onSSIDsChanged = (event, { value }) => {
    this.setState({ affectedSSIDs: value })
  }

  onNameChanged = (event) => {
    this.setState({ name: event.target.value })
  }

  onCustomerChanged = (customer) => {
    const { customers } = this.state
    this.setState({ customer })
    const customerObject = customers.find((c) => c.text === customer.name)
    client.listCustomerSsids(customerObject.uuid).then((ssids) => {
      const ssidList = ssids.map((ssid) => {
        return { text: ssid.alias, key: ssid.uid, value: ssid.uid }
      })
      this.setState({ ssids: ssidList })
    })
  }

  render() {
    const { isSending, messages, name, customer, contents, customers, ssids } =
      this.state
    const hasError = messages.some((message) => message.type === 'error')
    const hasWarning = messages.some((message) => message.type === 'warning')
    const isSuccess = !hasError && !hasWarning

    return (
      <Container>
        <Header as="h1" dividing>
          Build Captive Portal
        </Header>
        <Form
          error={hasError}
          warning={hasWarning}
          success={isSuccess}
          onSubmit={this.controller.handleSubmit.bind(this)}
          loading={isSending}
        >
          {renderMessages(messages)}

          <Header as="h3" dividing>
            CUSTOMER
          </Header>
          <SelectCustomerForm
            onChange={this.onCustomerChanged}
            name={customer}
            customers={customers}
          />

          <Header as="h3" dividing>
            SSIDs
          </Header>
          {ssids && (
            <Dropdown
              placeholder="SSIDs"
              fluid
              multiple
              selection
              search
              options={ssids}
              onChange={this.onSSIDsChanged}
            />
          )}

          <Header as="h3" dividing>
            CAPTIVE PORTAL
          </Header>
          <Form.Field>
            <label>Name</label>
            <input
              placeholder="Name"
              defaultValue={name}
              onChange={this.onNameChanged}
              required
            />
          </Form.Field>
          <Form.Field>
            <label>Farside contents</label>
            <textarea
              placeholder="Contents"
              defaultValue={contents}
              onChange={this.onContentsChanged}
              required
            />
          </Form.Field>

          <Header as="h3" dividing>
            REQUEST DETAILS
          </Header>
          <RequesterForm onChange={this.onRequestChanged} />

          <Button type="submit" disabled={isSending}>
            Submit
          </Button>
        </Form>
      </Container>
    )
  }
}

export default CaptivePortalController

import React, { Component } from 'react'
import { Segment, Form, Button } from 'semantic-ui-react'
import UserComponent from 'requests/components/UserComponent'

class AssignUserForm extends Component {
  constructor(props) {
    super(props)

    this.state = { users: {}, lastID: 0 }
  }

  addUser = (event) => {
    event.preventDefault()
    const { users, lastID } = this.state
    const updatedUsers = { ...users }
    const key = `user-${lastID + 1}`
    updatedUsers[key] = {
      firstName: '',
      lastName: '',
      email: '',
      role: 'admin',
    }
    this.setState({ users: updatedUsers, lastID: lastID + 1 })
  }

  removeUser = (key) => {
    const { users } = this.state
    const updatedUsers = { ...users }
    delete updatedUsers[key]
    this.setState({ users: updatedUsers })
  }

  updateUser = (key, firstName, lastName, email, role) => {
    const { users } = this.state
    const updatedUsers = { ...users }
    updatedUsers[key] = { firstName, lastName, email, role }
    this.setState({ users: updatedUsers })
  }

  handleChange(props) {
    const { onChange } = props
    onChange(this.state.users)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.handleChange(this.props)
    }
  }

  renderUser(user, key, collectNames) {
    return (
      <UserComponent
        {...user}
        key={key}
        uid={key}
        collectNames={collectNames}
        onUpdateUser={this.updateUser}
        onRemoveUser={this.removeUser}
      />
    )
  }

  renderUsers(users, collectNames = true) {
    const components = []
    Object.keys(users).forEach((key) => {
      components.push(this.renderUser(users[key], key, collectNames))
    })
    return components
  }

  render() {
    const { users } = this.state
    const { collectNames } = this.props

    return (
      <Segment>
        {this.renderUsers(users, collectNames)}
        <Form.Field>
          <Button content="Assign User" color="blue" onClick={this.addUser} />
        </Form.Field>
      </Segment>
    )
  }
}

export default AssignUserForm

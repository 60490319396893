import FormController from 'utils/forms/controllers/init'
import {
  ERROR_INVALID_REQUESTER_EMAIL,
  ERROR_MIN_DEVICES,
  ERROR_INVALID_DEVICE_MAC,
} from 'utils/forms/errors'
import {
  validateEmail,
  validateMacAddress,
  convertObjectToSnakeCaseKeys,
} from 'util'
import client from 'client'

class MoveDevicesFormController extends FormController {
  validate = () => {
    const { request, devices } = this.form.state

    let messages = []
    let validated = true

    if (!validateEmail(request.email)) {
      messages.push(this.makeValidationError(ERROR_INVALID_REQUESTER_EMAIL))
      validated = false
    }

    if (!devices || devices.length === 0) {
      messages.push(this.makeValidationError(ERROR_MIN_DEVICES))
      validated = false
    } else {
      Object.values(devices).forEach((device) => {
        if (!validateMacAddress(device.macAddress)) {
          messages.push(
            this.makeValidationError(
              ERROR_INVALID_DEVICE_MAC.replace('{DEVICE}', `${device.serial}`)
            )
          )
          validated = false
        }
      })
    }

    return { validated, messages }
  }

  submit = () => {
    const { request, customer, devices } = this.form.state
    const data = {
      devices: Object.values(devices).map((device) =>
        convertObjectToSnakeCaseKeys(device)
      ),
      name: customer && customer.name,
    }

    return client.requestWorkflow(request, 'move_devices', data)
  }
}

export default MoveDevicesFormController
